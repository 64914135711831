<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? m.title + ` №${data.code_doc}` : "Новый документ '" + m.title + "'" }}
        [{{ statusCurrent.text || "" }}]
        <br />
        {{ config.name }}
      </template>
      <a-loader v-if="loading" />
      <v-row v-else>
        <a-form-info v-if="0 == 1" :value="data" />
        <v-col cols="12" sm="12" class="pb-0" v-if="showSelectDocButton">
          <s-document-btn-list v-if="!true" name="Выбрать основание" :source="config.document" :parent_data="data" @select="onSelectDocument($event)" />
          <v-btn @click="showSelectDialog = true"> Выбрать основание </v-btn>
        </v-col>
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name])"
              :errors="errors"
              :readonly="data.status == 2"
              :config="{ dense: true, readonly }"
              @fieldData="onFieldData($event)"
              @validate="validate($event)"
              @changeValidator="fillFormValidators($event, false)"
            />
          </div>
        </v-col>
      </v-row>

      <template v-slot:actions>
        <a-btn-status v-if="!loading && isStatus" :items="statusItems" @click="changeStatus($event)" :disabled="!canStatus" />
        <v-btn v-if="!loading && data.status == 2" :href="getPreview()" target="_blank" class="mx-1" dark color="info" title="Печать">
          <v-icon dark>fas fa-print </v-icon>
        </v-btn>
        <a-btn-acc-record v-if="data.status" :id="id" :name="m.accDocName" />
        <v-spacer></v-spacer>
        <a-btn-import-data v-if="data.import_data" :value="data.import_data" :model="{ name: 'import_data', title: 'Данные импорта' }" />
        <a-btn-delete v-if="!loading && id && isDelete" @click="removeDialogShow = true" />
        <a-btn-save v-if="!loading && isEdit" @click="submit()" :color="isChanged ? 'green' : 'primary'" />

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <select-dialog v-model="showSelectDialog" v-if="showSelectDialog" :config="config" @select="onSelectDocument($event)" />
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";
import libsCashAccess from "./../../libsCashAccess";

export default {
  components: {
    selectDialog: () => import("./selectDialog"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel, libsCashAccess],
  props: {
    value: Boolean,
    id: Number,
    initData: Object,
    document: {
      type: Object,
      default: () => {
        return {};
      },
    },

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      m: this.config.m,
      api: this.config.m.api,
      apiStatus: this.config.m.api,
      removeDialogShow: false,
      showSelectDialog: false,
      suEditShow: false,
      loaded: false,
      baseId: null,
      modelForm: null,
      isPrint: false,
      model: [],
    };
  },
  computed: {
    showSelectDocButton() {
      return !this?.id && (!this?.document?.id || !this.data?.parent_id) && this.data.status != 2;
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },

    canStatus() {
      return !this.loading && !this.isChanged && this.data.id;
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statuses = this.m.statuses;
      const statusCur = statuses.find((el) => el.value == this.data.status);
      if (statusCur && statusCur.next && statuses) {
        let arr = statusCur.next;
        res = statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));
      return items;
    },
    statusCurrent() {
      let statusCur = this.m.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (this.id) {
    } else {
      this.afterFetchData();
      this.onInitData();
      this.data.parent_name = this.config.document.accDocName;
      this.data.operation_type = this.config.id;
      if (this.document?.id) {
        this.onSelectDocument(this.document);
      } else {
        this.showSelectDialog = true;
      }
    }
  },
  watch: {
    value1() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
          this.type = 1;
          //  this.data.status = 0;
        }
      }
    },
  },
  methods: {
    getPreview() {
      return this.$root.config.baseUrl + this.api + `/${this.id}/print?token=` + localStorage.getItem("user-token");
    },
    onInitData() {
      if (!this.initData) return;
      for (const key in this.initData) {
        this.data[key] = this.initData[key];
      }
    },
    onFieldData(e) {
      if (e.name == "balance") this.data[e.name] = e.value;
    },
    onSelectDocument(e) {
      this.loading = true;
      if (this.config.document?.fieldsConfig) {
        const config = this.config.document?.fieldsConfig;
        for (const key in config) {
          if (this.data.hasOwnProperty(key) || true) {
            if (config[key].type === "field") {
              this.setData(key, e[config[key].value]);
              this.validate(key);
            } else if (config[key].type === "value") {
              this.setData(key, config[key].value);
              this.validate(key);
            } else {
              console.log("не известный тип сопоставления данных " + config[key].type);
            }
          } else {
            console.log("не найдено поле для сопоставления данных " + key);
          }
        }
      }
      this.data["_document"] = e;
      this.data.parent_id = e.id;
      this.data.parent_name = this.config.document.accDocName;
              this.validate('parent_id');
              this.validate('parent_name');
      this.loading = false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.isChanged = false;
      this.id = data.id;
      return;
      if (status) this.$emit("input");
    },
    afterFetchData(r) {
      this.model = this.calcModel();
      if (this.id) {
      }
      this.loaded = true;
      // this.initType = null;
    },

    calcModel(t) {
      //оплата по основанию
      const config = this.config;
      this.isPrint = config?.isPrint || false;
      let model = JSON.parse(JSON.stringify(this.m.form)) || [];
      if (config?.fieldsReplace) {
        Object.keys(config.fieldsReplace).forEach((f) => {
          let idx = model.findIndex((el) => {
            return el.name == f;
          });
          if (idx !== -1) model[idx] = config.fieldsReplace[f];
          else model.push(config.fieldsReplace[f]);
        });
      }

      let fields = config.fields ? config.fields.split(",") : [];
      if (config.fieldsStatusDel && config.fieldsStatusDel[this.data.status]) {
        fields = fields.filter((f) => {
          return !config.fieldsStatusDel[this.data.status].split(",").includes(f);
        });
      }
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      if (this.data.status == 2) fieldsRO = fields;
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });
      this.modelForm = mForm;
      this.fillFormFromModel(model);
      return model;
    },
    async changeStatus(status) {
      let id = this.data.id;
      if (!id) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Что-то пошло не так....",
        });
        return;
      }
      if (this.config?.balanceCheck) {
        if (status.value == 2) {
          if (!this.data.balance) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "ОШИБКА. Нет данных баланса",
            });
            return false;
          }
          let b = this.data.balance;
          if (b.credit - b.debit - this.data.value < 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "ОШИБКА. Баланс по документу отрицательный",
            });
            return false;
          }
        }
      }
      if (status.value == 2)
        if (await this.validateAll(this.data, true)) {
        } else return;
      this.loading = true;
      let data = { id, status: status.value };
      let response;
      try {
        response = await this.$axios.post(this.apiStatus, data);
      } catch (error) {
        response = error;
      }
      this.loading = false;
      status = response.data.status == "ok";
      if (status) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      } else {
        this.$root.$emit("show-info", {
          text: "Ошибка " + response?.data?.message,
          type: "error",
        });
      }
    },
  },
};
</script>
